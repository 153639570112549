import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ikylogo from "../assets/images/iky.png";
import "./Nav.css";

function Nav() {
  const [scrollingDown, setScrollingDown] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // Dernière position du scroll
  const [navVisible, setNavVisible] = useState(true); // Pour gérer la visibilité du nav

  // Fonction pour gérer le défilement
  const handleScroll = () => {
    const currentScrollY = window.scrollY; // Position actuelle du scroll

    // Si on descend la page et que l'on est plus bas que 100px, cacher la barre
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setNavVisible(false);
    } else {
      setNavVisible(true);
    }

    // Mettre à jour la dernière position de scroll
    setLastScrollY(currentScrollY);
  };

  // Ajout de l'événement de défilement et nettoyage lors du démontage du composant
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className={`nav-content ${navVisible ? "" : "nav-hidden"}`}>
      {" "}
      {/* Appliquer la classe nav-hidden si le menu est caché */}
      <img src={ikylogo} alt="Logo" className="logo" />
      <ul className="list-styled">
        <li>
          <Link to="/" className="link-styled">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="link-styled">
            C.R.Kan
          </Link>
        </li>
        <li>
          <Link to="/yoga" className="link-styled">
            Yoga
          </Link>
        </li>
        <li>
          <Link to="/iky" className="link-styled">
            IKYnesis
          </Link>
        </li>
        <li>
          <Link to="/kankueb" className="link-styled">
            Kankueb
          </Link>
        </li>
        <Link to="/ripey" className="link-styled">
          RIPEY
        </Link>
        <li>
          <Link to="/ishka" className="link-styled">
            Ishka Center
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Nav;
